import {
  format,
  differenceInDays,
  formatDistanceToNow,
  differenceInSeconds,
} from 'date-fns'
import { fr } from 'date-fns/locale/fr'

const dateFormat = (val: string | number | Date, formatStr: string) => {
  return !val ? '' : format(new Date(val), formatStr, { locale: fr })
}

export const dateToString = (value?: string | number | Date) => {
  return value ? dateFormat(new Date(value), 'PPP') : ''
}

export const dateToStringMonthYear = (value: string | number | Date) => {
  return dateFormat(new Date(value), 'MMMM yyyy')
}

export const dateToStringDayMonthYear = (value?: string | number | Date) => {
  if (value == undefined) return ''
  return dateFormat(new Date(value), 'dd/MM/yyyy')
}

export const countdown = (value?: string | Date) => {
  if (!value) return 0
  return differenceInDays(new Date(value), new Date())
}

export const countdownS = (value?: string | Date) => {
  if (!value) return 0
  return differenceInSeconds(new Date(value), new Date())
}

export const dateToMoment = (value?: string | number | Date) => {
  if (value == undefined) return ''
  return formatDistanceToNow(new Date(value), { locale: fr })
}

export const datePublication = (value: string | number | Date) => {
  return dateFormat(value, 'dd/MM/yyyy à H:mm')
}
